import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  buttonOk: {
    id: 'components.Modal.Alert.buttonOk',
    defaultMessage: 'Aceptar'
  },
  buttonCancel: {
    id: 'components.Modal.Alert.buttonCancel',
    defaultMessage: 'Cancelar'
  }
});