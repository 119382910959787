import _get from "lodash/get";
import _keys from "lodash/keys";
import _isObject from "lodash/isObject";
export const defaultGetOptionLabel = option => {
  if (!option) return '';

  if (_isObject(option)) {
    const keys = _keys(option);

    return _get(option, keys[0]);
  }

  return option;
};